import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import History from "../../components/controls/yoga/history/history";
import { fetchSequences } from "../../redux/fetchSequences";
import { Divider, Grid, GridItem, Heading } from "@chakra-ui/react";

export function AllSequences(){
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSequences(0));
    }, [dispatch]);

    return (
        <Grid minH='100%' templateRows='auto 1fr' templateColumns='1'>
            <GridItem>
                <Heading>Sequences</Heading>
                {/* <Heading size='md' mb={5}>Get inspired by the yoga sequence! Yoga sequencing tool</Heading> */}
                <Divider my={8} />
            </GridItem>
            <GridItem>
                <History />
            </GridItem>
        </Grid>
    );
}