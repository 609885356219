import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import '@fontsource/graduate';
import { withProse } from '@nikolovlazar/chakra-ui-prose'

export const Theme = extendTheme({
  styles: {
    global: {
      '.fullscreen-enabled': {
        background: '#fff',
      },
      li: {
        fontWeight: 'var(--chakra-fontWeights-normal)',
      },
    },
  },
  fontWeights: {
    hairline: 100,
    thin: 100,
    light: 100,
    normal: 100,
    medium: 100,
    semibold: 100,
    bold: 100,
    extrabold: 100,
    black: 100,
  },
  colors: {
    // primary: {
    //   50: "#f6f8f1",
    //   100: "#dbe4c9",
    //   200: "#bbcc9a",
    //   300: "#98ae6c",
    //   400: "#889b60",
    //   500: "#738351",
    //   600: "#616e44",
    //   700: "#4e5937",
    //   800: "#414b2e",
    //   900: "#2f3621"
    // },
    // secondary: {
    //   50: '#fcfaee',
    //   100: '#f6f2cf',
    //   200: '#ece49b',
    //   300: '#e5d677',
    //   400: '#dbc044',
    //   500: '#d2a52e',
    //   600: '#ba8425',
    //   700: '#9b6222',
    //   800: '#7e4e22',
    //   900: '#68401f',
    //   950: '#3b220d',
    // },
    primary: {
      50: "#F3EADA",
      100: "#F3EADA",
      200: "#F3EADA",
      300: "#F3EADA",
      400: "#009EA4",
      500: "#009EA4",
      600: "#009EA4",
      700: "#009EA4",
      800: "#009EA4",
      900: "#009EA4"
    },
    secondary: {
      50: '#FCBB38',
      100: '#FCBB38',
      200: '#FCBB38',
      300: '#FFC1B4',
      400: '#FFC1B4',
      500: '#FCBB38',
      600: '#FCBB38',
      700: '#C1554E',
      800: '#C1554E',
      900: '#C1554E',
    }
  },
  fonts: {
    title: {
      header: `'Graduate', sans-serif`,
    },
    heading: `'Roboto', sans-serif`,
    body: `'Roboto', sans-serif`,
  },
  components: {
    Button: {
      sizes: {
        md: {
          fontWeight: "400"
        }
      }
    }
  }
},
  withDefaultColorScheme({ colorScheme: 'primary' }),
  withProse({
    baseStyle: {
      img: {
        maxWidth: "100%"
      },
      
      table: {
        border: "1px solid #EAEAEA",
        borderRadius: "3px",
        padding: "5px",
        margin: "15px 0",
        borderCollapse: "collapse",
        width: "100%"
      },
      
      th: {
        border: "1px solid #EAEAEA",
        borderRadius: "3px",
        padding: "5px",
      },
      
      td: {
        border: "1px solid #EAEAEA",
        borderRadius: "3px",
        padding: "5px",
      },
      
      body: {
          fontFamily: `'Roboto', sans-serif`,
      padding: "1em",
      margin: "auto",
      maxWidth: "42em",
      background: "#fefefe"
      },
      
      h1: {
        fontWeight: "bold",
          color: "#000000",
          fontSize: "28px"
      },
      
      h2: {
        fontWeight: "bold",
          borderBottom: "1px solid #CCCCCC",
          color: "#000000",
          fontSize: "24px"
      },
      
      h3: {
        fontWeight: "bold",
          fontSize: "18px"
      },
      
      h4: {
        fontWeight: "bold",
          fontSize: "16px"
      },
      
      h5: {
        fontWeight: "bold",
          fontSize: "14px"
      },
      
      h6: {
        fontWeight: "bold",
          color: "#777777",
          backgroundColor: "inherit",
          fontSize: "14px"
      },
      
      hr: {
          height: "0.2em",
          border: "0",
          color: "#CCCCCC",
          backgroundColor: "#CCCCCC"
      },
      
      p: {
          margin: "15px 0"
      },
      
      blockquote: {
          margin: "15px 0"
      },
      
      ul: {
          margin: "15px 0"
      },
      
      ol: {
          margin: "15px 0", 
          ul : {
            marginLeft: "30px"
          }
      },
      
      dl: {
          margin: "15px 0"
      },
      
      li: {
          margin: "15px 0"
      },
      
      code: {
          border: "1px solid #EAEAEA",
          margin: "0 2px",
          padding: "0 5px",
          borderRadius: "3px",
          backgroundColor: "#F8F8F8",
          color: "inherit"
      },
      
      pre: {
          border: "1px solid #CCCCCC",
          lineHeight: "1.25em",
          overflow: "auto",
          padding: "6px 10px",
          margin: "15px 0",
          borderRadius: "3px",
          backgroundColor: "#F8F8F8",
          color: "inherit"
      },
      
      a: {
          color: "#889b60",
          backgroundColor: "inherit",
          textDecoration: "none",
      },
    }
  }))