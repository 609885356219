import { useColorMode } from "@chakra-ui/react";

export default function Logo(props) {
  const { colorMode } = useColorMode();
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
      height={24} viewBox="0 0 256 256"
      {...props}>
      <path fill={colorMode === "light" ? "#010101" : "#D9D9D9"} opacity="1.000000" stroke="none"
        d="
M99.198280,49.085220 
	C129.251053,38.870380 156.299286,43.548775 180.712967,63.278915 
	C197.357605,76.730431 207.484329,94.368835 210.699509,115.145630 
	C217.842651,161.305450 187.988358,203.982376 141.095001,212.046539 
	C96.547798,219.707230 53.820564,187.593887 45.869900,146.010483 
	C38.214787,105.972855 58.477081,66.398125 95.737152,50.717194 
	C96.793098,50.272797 97.784058,49.673992 99.198280,49.085220 
M134.033997,99.676018 
	C134.518875,99.907288 135.003769,100.138565 135.488647,100.369843 
	C135.417801,99.941277 135.346954,99.512711 135.095505,98.361656 
	C142.607529,86.806473 139.488663,82.486084 127.764702,67.968620 
	C113.566154,85.248116 115.420738,89.771507 120.005028,96.447777 
	C120.005028,93.949036 119.914352,91.613899 120.024925,89.288330 
	C120.222076,85.142090 121.679749,81.739738 126.290062,81.053551 
	C131.358337,80.299202 134.385666,82.471565 135.587448,87.705002 
	C135.979431,89.411972 135.649063,91.284813 135.649063,93.081795 
	C134.751923,93.596298 133.854782,94.110809 132.957626,94.625313 
	C132.830215,93.121590 132.702805,91.617859 132.575394,90.114136 
	C131.892136,90.237831 131.208893,90.361526 130.525650,90.485222 
	C129.006195,93.974121 127.145927,97.360458 126.057007,100.978928 
	C124.807053,105.132484 122.537048,107.787277 118.162331,107.879250 
	C113.620346,107.974731 112.503380,104.621597 111.997810,100.908325 
	C111.887955,100.101501 111.639816,99.314514 111.486893,98.512375 
	C109.604301,88.637695 108.866402,79.400490 118.196793,71.937248 
	C121.561043,69.246254 122.853340,63.949234 125.046593,59.817802 
	C125.904495,58.201771 126.639984,56.520752 127.667999,54.375145 
	C128.658096,55.546833 129.334564,55.979702 129.431335,56.516983 
	C130.910080,64.727409 135.801193,70.749283 141.502701,76.462723 
	C143.304398,78.268181 144.637131,81.245087 144.861664,83.802032 
	C145.974091,96.469933 142.381836,108.343544 137.265854,119.682037 
	C134.799850,125.147446 134.505936,129.949158 137.437469,135.134995 
	C140.702621,140.910980 140.779419,147.200089 139.349564,153.431183 
	C137.270416,162.491852 134.566895,171.412598 132.605026,180.495697 
	C131.257019,186.736694 130.586441,193.137192 129.860962,199.494690 
	C129.406464,203.477448 132.326431,206.633118 135.386871,206.507141 
	C140.953613,206.277954 146.449417,204.325256 151.974838,203.091751 
	C151.898163,202.455750 151.821503,201.819763 151.744827,201.183762 
	C145.899551,201.183762 140.054276,201.183762 134.209015,201.183762 
	C134.025604,200.471512 133.842209,199.759247 133.658813,199.046997 
	C135.108688,198.390762 136.562317,197.164169 138.007797,197.173721 
	C145.640656,197.224152 153.274689,197.812134 160.901031,197.703430 
	C163.216705,197.670425 165.509201,196.011658 167.812302,195.097519 
	C158.182297,194.016205 149.017380,194.124969 139.859528,193.919388 
	C138.141052,193.880798 136.450760,192.586090 134.747467,191.871033 
	C136.379074,191.251068 138.002518,190.120483 139.643631,190.094208 
	C150.138458,189.926193 160.647903,189.719727 171.129929,190.118973 
	C176.079437,190.307495 178.326965,187.640015 181.026703,183.992279 
	C174.253021,183.992279 168.083801,184.131424 161.929459,183.890656 
	C160.462402,183.833252 159.044998,182.506882 157.604767,181.764175 
	C159.020233,180.910004 160.404877,179.384979 161.856537,179.318253 
	C168.008911,179.035477 174.201218,178.878433 180.339645,179.278427 
	C184.974869,179.580460 187.877792,178.158203 190.517288,174.205109 
	C203.401169,154.909134 208.277390,134.071930 202.939606,111.331871 
	C198.794357,93.672249 189.592407,79.084503 175.334778,67.612663 
	C157.774460,53.483448 137.824677,47.429516 115.760635,51.276218 
	C95.968895,54.726761 79.817314,65.061943 67.192696,81.063698 
	C45.213924,108.921875 45.891544,148.087784 66.595573,175.961700 
	C67.832878,177.627502 70.629425,178.837952 72.786797,178.983963 
	C78.924721,179.399384 85.115059,178.990585 91.267181,179.280029 
	C92.865829,179.355240 94.400078,180.799271 95.963905,181.614609 
	C94.473427,182.406250 93.014488,183.800629 91.486763,183.880600 
	C86.124939,184.161255 80.739449,183.989517 74.818672,183.989517 
	C80.013275,190.000168 80.013275,189.999573 86.517967,190.000870 
	C96.515533,190.002853 106.513100,190.003159 116.510666,190.010223 
	C118.847504,190.011871 121.459740,189.713913 120.630959,193.951965 
	C109.902580,193.951965 99.190773,193.951965 88.478958,193.951965 
	C88.364815,194.389389 88.250671,194.826828 88.136520,195.264252 
	C90.444572,196.061127 92.740746,197.492477 95.062889,197.535904 
	C102.032196,197.666306 109.011497,197.146408 115.987228,197.137711 
	C117.562485,197.135742 119.139053,198.177322 120.715019,198.738525 
	C120.605507,199.328003 120.495987,199.917480 120.386475,200.506958 
	C114.726105,200.898056 109.065735,201.289154 103.405357,201.680267 
	C107.511917,203.894623 111.552544,205.202332 115.693077,205.974167 
	C122.180824,207.183609 125.437157,204.571625 124.902351,197.905258 
	C124.226631,189.482361 123.402534,181.155380 126.031036,173.034378 
	C126.031036,173.034378 125.921463,173.109772 126.756409,172.775894 
	C126.294373,165.138138 129.525009,157.042557 123.976852,150.073944 
	C125.660843,148.561035 127.424492,147.121658 129.021362,145.516388 
	C135.372589,139.131790 130.412598,127.112831 123.920975,125.510406 
	C123.920975,125.510406 123.859650,125.455841 124.043846,124.551933 
	C124.451820,123.723930 124.643227,122.585533 125.306175,122.122971 
	C127.844925,120.351608 130.535187,118.797386 133.825577,116.847282 
	C138.328720,112.462158 137.007980,107.249840 136.130508,102.072273 
	C137.100693,107.326874 135.208008,111.794914 131.682022,116.324234 
	C124.424774,121.023567 120.733696,121.020576 116.121185,115.728706 
	C116.411430,115.162407 116.701675,114.596107 117.857887,114.021286 
	C122.838661,114.472206 127.558838,113.814392 129.811569,108.715729 
	C131.136414,105.717171 131.417099,102.257256 132.738678,99.015961 
	C133.119385,99.028122 133.500092,99.040283 133.970367,99.084671 
	C133.970367,99.084671 133.929886,99.170830 134.033997,99.676018 
z"/>
      <path fill={colorMode === "light" ? "#010101" : "#D9D9D9"} opacity="1.000000" stroke="none"
        d="
M126.079018,172.988327 
	C125.388145,173.209335 124.649284,173.476395 123.467728,173.903473 
	C122.418236,163.410019 114.801285,161.041473 106.223206,159.998535 
	C102.462479,159.541275 98.569130,159.717438 94.939232,158.800995 
	C92.488899,158.182358 89.150520,156.577484 88.495125,154.640411 
	C87.860573,152.764969 89.470093,148.778381 91.282158,147.680923 
	C96.682281,144.410400 102.441673,141.295959 108.477074,139.619293 
	C116.503662,137.389450 120.922028,133.342285 119.952759,124.245308 
	C121.231293,124.729561 122.061142,125.043869 123.375320,125.407013 
	C123.859650,125.455841 123.920975,125.510406 123.901207,125.901062 
	C131.812469,135.539658 128.933411,148.294312 114.379768,149.983871 
	C110.109512,150.479599 105.838684,150.970337 100.922905,151.537842 
	C109.585976,154.117737 117.641045,156.516571 125.921463,158.982513 
	C125.921463,163.481537 125.921463,168.295654 125.921463,173.109772 
	C125.921463,173.109772 126.031036,173.034378 126.079018,172.988327 
z"/>
      <path fill={colorMode === "light" ? "#010101" : "#D9D9D9"} opacity="1.000000" stroke="none"
        d="
M115.993195,116.020065 
	C115.394989,115.297913 114.924782,114.284401 114.454582,113.270889 
	C115.164780,113.347847 115.874977,113.424805 116.788544,113.765785 
	C116.701675,114.596107 116.411430,115.162407 115.993195,116.020065 
z"/>
      <path fill={colorMode === "light" ? "#010101" : "#D9D9D9"} opacity="1.000000" stroke="none"
        d="
M132.453049,99.010406 
	C132.106262,98.342003 132.045120,97.679153 131.926147,96.389389 
	C132.897964,97.466003 133.385651,98.006287 133.877075,98.799507 
	C133.500092,99.040283 133.119385,99.028122 132.453049,99.010406 
z"/>
      <path fill={colorMode === "light" ? "#010101" : "#D9D9D9"} opacity="1.000000" stroke="none"
        d="
M133.981934,99.423424 
	C134.160126,99.247681 134.390381,99.324539 134.948380,99.242767 
	C135.346954,99.512711 135.417801,99.941277 135.488647,100.369843 
	C135.003769,100.138565 134.518875,99.907288 133.981934,99.423424 
z"/>
      <path fill={colorMode === "light" ? "#010101" : "#D9D9D9"} opacity="1.000000" stroke="none"
        d="
M106.693245,182.698059 
	C105.903122,183.325119 105.230019,183.988312 104.789574,183.859009 
	C103.231850,183.401703 101.753708,182.673294 100.245399,182.047668 
	C101.039841,181.170319 101.734581,179.690521 102.649162,179.539185 
	C104.642998,179.209229 107.258713,178.815506 106.693245,182.698059 
z"/>
      <path fill={colorMode === "light" ? "#010101" : "#D9D9D9"} opacity="1.000000" stroke="none"
        d="
M147.622650,180.057480 
	C148.276123,179.768036 148.666748,179.494812 148.881287,179.595764 
	C150.402313,180.311523 151.887283,181.103928 153.383743,181.871918 
	C152.327271,182.580276 151.339798,183.731140 150.200562,183.908783 
	C147.473495,184.334030 146.112411,183.175949 147.622650,180.057480 
z"/>
    </svg>
  );
};