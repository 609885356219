import { Flex } from "@chakra-ui/react"
import React from 'react';
import NewSequence from "../yoga/newSequence";

export default function Home(props) {


    return (
            <Flex
                direction="column"
                align="center"
                m="0 auto"
                {...props}
            >
                <Flex
                    align="center"
                    justify={{ base: "center", md: "space-around", xl: "space-between" }}
                    direction={{ base: "column", md: "row" }}
                    wrap="no-wrap"
                    w="100%"
                >
                <NewSequence />
                </Flex>
            </Flex>
    )
}