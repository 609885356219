import { Alert, AlertIcon, AlertTitle, Button, Checkbox, Divider, Grid, GridItem, HStack, Heading, IconButton, Input, InputGroup, InputRightElement, Link, Spacer, Stack, Text } from "@chakra-ui/react"
import React, { useEffect, useState } from 'react';
import api from "../../services/Api";
import { PageLoading } from "../../components/controls/pageLoading";
import { useAuth } from "../../hooks/useAuth";
import TextDivider from "../../components/controls/textDivider";
import { Check, X } from "react-feather";
import { TrackCommunityUpdateCheckboxIntentEvent, TrackNewUpdatesCheckboxIntentEvent, TrackPromotionsCheckboxIntentEvent, TrackSubscriptionPortButtonIntentEvent } from "../../utils/google-analytics/events/profileForm";

export default function Profile(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [session, setSession] = useState({});
    const [error, setError] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const { user, updateUser } = useAuth();
    const [ userName, setUserName ] = useState(user.name);
    const [ userNameEdit, setUserNameEdit ] = useState(user.name);
    const [ isNewsAndUpdates, setIsNewsAndUpdates ] = useState(user.is_news_and_updates);
    const [ isPromotions, setIsPromotions ] = useState(user.is_promotions);
    const [ isCommunityUpdates, setIsCommunityUpdates ] = useState(user.is_community_updates);
    const [ isTransactionalEmails, setIsTransactionalEmails ] = useState(user.is_transactional_emails);

    useEffect(() => {
            function loadPortalSession()
            {
                api.get(`/api/portal/session`)
                    .then((response) => {
                        setIsLoading(false);
                        setSession(response.data);
                    })
                    .catch((errorMessage) => {
                        setIsLoading(false);
                        setIsError(true);
                        setError(`Could not load subscription data. Please contact our support <b><a href='mailto:support@greatinnature.com'>support@greatinnature.com</a></b>`);
                    });
            }

            function updateUserInfo(){
                api.post(`/api/user`, {name: userName, is_news_and_updates: isNewsAndUpdates, is_promotions: isPromotions, is_community_updates: isCommunityUpdates })
                        .then((response) => {
                            updateUser(response.data);
                        })
                        .catch((errorMessage) => {
                            setIsError(true);
                            setError(errorMessage);
                        });
            }
            setIsError(false);
            setError("");

            if (!session.url)
            {
                loadPortalSession();
            }
            
            if (initialized)
            {
                updateUserInfo();
            }
            setInitialized(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName, isNewsAndUpdates, isPromotions, isCommunityUpdates]);

    if (isLoading) {
        return <PageLoading />;
    }


    return (
        <Grid minH='100%' templateRows='auto 1fr' templateColumns='1'>
            
            <GridItem>
                <HStack>
                    <Heading size='lg'>My private info</Heading>
                    <Spacer />
                    {session.url &&
                    <Link href={session.url} onClick={() => TrackSubscriptionPortButtonIntentEvent()}>
                        <Button>Subscription portal</Button>
                    </Link>}
                </HStack>
                <Divider my={8} />
                {isError && (
                    <Alert status='error' mb={5}>
                        <AlertIcon />
                        <AlertTitle><div dangerouslySetInnerHTML={{ __html: error}} /></AlertTitle>
                    </Alert>
                )}
            </GridItem>
            
            <GridItem>
                <Text>Fullname</Text>
                <InputGroup>
                <Input
                    value={userNameEdit}
                    onChange={(event) => setUserNameEdit(event.target.value)}
                    size='lg'
                    mb={3}
                />
                <InputRightElement mt={1} me={5} hidden={userName === userNameEdit}>
                  <IconButton variant='outline' border={0} size='sm' me={1} aria-label='Save' icon={<Check />} onClick={() => setUserName(userNameEdit)}/>
                  <IconButton variant='outline' border={0} size='sm' aria-label='Cancel' icon={<X />} onClick={() => setUserNameEdit(userName)}/>
                </InputRightElement>
                </InputGroup>
                <Text>Email</Text>
                <Input
                    value={user.email}
                    size='lg'
                    mb={3}
                    disabled
                />
                <TextDivider label='My Notifications' />
                <Stack mt={8}>
                <Checkbox 
                    isChecked={isNewsAndUpdates}
                    onChange={(event) => {
                        TrackNewUpdatesCheckboxIntentEvent();
                        setIsNewsAndUpdates(event.target.checked);
                    }}>News & Updates</Checkbox>
                <Checkbox 
                    isChecked={isPromotions}
                    onChange={(event) => {
                        TrackPromotionsCheckboxIntentEvent();
                        setIsPromotions(event.target.checked);
                    }}>Promotions</Checkbox>
                <Checkbox 
                    isChecked={isCommunityUpdates}
                    onChange={(event) => {
                        TrackCommunityUpdateCheckboxIntentEvent();
                        setIsCommunityUpdates(event.target.checked);
                    }}>Community updates</Checkbox>
                <Checkbox 
                    isChecked={isTransactionalEmails}
                    onChange={(event) => {
                        setIsTransactionalEmails(event.target.checked);
                    }} 
                    isDisabled>Transactional emails</Checkbox>
                </Stack>
            </GridItem>
            {/* <GridItem>
                <Divider my={8} />
                <Button color='white' isLoading={isLoading} onClick={() => updateUserInfo()}>Save</Button>
            </GridItem> */}
        </Grid>);
}