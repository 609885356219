import { Alert, AlertDescription, AlertIcon, AlertTitle, Heading, SimpleGrid, Stack } from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from 'react';
import api from "../../services/Api";
import { PageLoading } from "../../components/controls/pageLoading";

export default function Subscription(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [session, setSession] = useState({});
    const [error, setError] = useState(null);
    const initialized = useRef(false);

    useEffect(() => {
        if (initialized.current !== true) {
            initialized.current = true;

            api.get(`/api/checkout/session`)
                .then((response) => {
                    setIsLoading(false);
                    setIsError(false);
                    setSession(response.data);
                })
                .catch((errorMessage) => {
                    setIsLoading(false);
                    setIsError(true);
                    setError(errorMessage);
                });
        }
    });

    if (isLoading) {
        return <PageLoading />;
    }

    if (isError) {
        return (<Alert status='error'>
            <AlertIcon />
            <AlertTitle>Cannot load data!</AlertTitle>
            <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>)
    }


    return (
        <Stack>
            <Heading as='h2'>Subscription</Heading>
            <SimpleGrid minChildWidth='250px'>
                <stripe-pricing-table
                    pricing-table-id={session.pricing_table}
                    publishable-key={session.public_key}
                    customer-session-client-secret={session.client_secret}>
                </stripe-pricing-table>
            </SimpleGrid>
        </Stack>
    )
}