import { Flex, Stack, HStack, Link, Heading, AbsoluteCenter, Text, Box, Center, Button, Image, SimpleGrid, FormControl, Input, Hide, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Checkbox, FormErrorMessage, AlertDescription, Alert } from "@chakra-ui/react"
import Logo from "../../components/controls/logo";
import LandingImage from "../../resources/images/landing.webp"
import React, { useState } from 'react';
import GoogleAuth from "../../components/auth/google";
import api from "../../services/Api";
//import Microsoft from "../components/auth/microsoft";
import Facebook from "../../components/auth/facebook";
import LinkedIn from "../../components/auth/linkedin";
import { Navigate, NavLink } from "react-router-dom";
import { TrackLoginButtonClickEvent, TrackLoginProviderRedirectSuccessEvent, TrackLoginProviderRedirectErrorEvent } from "../../utils/google-analytics/events/loginForm";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";

export default function Landing(props) {
    const {
        handleSubmit: handleLoginSubmit,
        register: registerLogin,
        reset: resetLogin,
        formState: { errors: loginErrors, isSubmitting: isLoginSubmitting }
    } = useForm()
    const {
        handleSubmit: handleRecoverySubmit,
        register: registerRecovery,
        reset: resetRecovery,
        formState: { errors: recoveryErrors, isSubmitting: isRecoverySubmitting }
    } = useForm()
    const {
        handleSubmit: handleRegisterSubmit,
        register: registerRegister,
        reset: resetRegister,
        formState: { errors: registerErrors, isSubmitting: isRegisterSubmitting },
        watch
    } = useForm()
    const { isOpen: isRecoveryOpen, onOpen: onRecoveryOpen, onClose: onRecoveryClose } = useDisclosure()
    const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure()
    const { isOpen: isEmailConfirmationOpen, onOpen: onEmailConfirmationOpen, onClose: onEmailConfirmationClose } = useDisclosure();

    const { login } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [registerError, setRegisterError] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const [recoveryError, setRecoveryError] = useState(null);
    const [isLoginSuccess, setIsLoginSuccess] = useState(false);

    const csrf = () => api.get('/sanctum/csrf-cookie');

    const getLoginUrl = async (type) => {

        TrackLoginButtonClickEvent(type);
        api.get(`/api/auth/redirect/${type}`)
            .then((data) => {
                TrackLoginProviderRedirectSuccessEvent(type);
                window.location.href = data.data.url
            })
            .catch((error) => {
                TrackLoginProviderRedirectErrorEvent(type, error);
                console.error(error)
            });
    }

    const onSubmitLogin = async (values, e) => {
        try {
            e.preventDefault()
            clearErrors();
            setIsLoading(true);
            setIsLoginSuccess(false);
            await csrf();
            await api.post(`/login`, { email: values.login_email, password: values.login_password });
            setIsLoginSuccess(true);
            const data = await api.get('/api/user');
            login(data.data);
            setLoggedIn(true);
            clearFields();
            setIsLoginSuccess(false);
        }
        catch (e) {
            setLoginError(e);
        }
        finally{
            setIsLoading(false);
        }
    }

    const onSubmitRegister = async (values) => {
        try {
            clearErrors();
            setIsLoading(true);
            await csrf();
            await api.post(`/register`, { name: values.register_name, email: values.register_email, password: values.register_password, password_confirmation: values.register_password2 });
            onRegisterClose();
            clearFields();
            onEmailConfirmationOpen();
            setIsLoginSuccess(false);
        }
        catch (e) {
            setRegisterError(e);
        }
        finally{
            setIsLoading(false);
        }
    }

    const onSubmitRecovery = async (values) => {
        try {
            clearErrors();
            setIsLoading(true);
            await csrf();
            await api.post(`/forgot-password`, { email: values.recovery_email });
            
            onRecoveryClose();
            clearFields();
            setIsLoginSuccess(false);
        }
        catch (e) {
            setRecoveryError(e);
        }
        finally{
            setIsLoading(false);
        }
    }

    const onConfirmationResendLinkClick = async () => {
        try{
            clearErrors();
            clearFields();
            setIsLoading(true);
            await api.post("/email/verification-notification");
            onEmailConfirmationOpen();
            setIsLoginSuccess(false);
        }
        catch (e)
        {
            setLoginError(e);
        }
        finally{
            setIsLoading(false);
        }
    }

    const clearErrors = () => {
        setLoginError(null);
        setRecoveryError(null);
        setRegisterError(null);
    }

    const clearFields = () => {
        resetRecovery();
        resetRegister();
        resetLogin();
    }

    if (isLoggedIn) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Flex
                direction="column"
                align="center"
                minH={'100vh'}
                m="0 auto"

                bg="#f0f2f5"
                color="chakra-body-text"
                {...props}
            >
                <AbsoluteCenter bgColor="white" width="80%" rounded={8}>
                    <SimpleGrid columns={2} shadow="dark-lg" rounded={8} minChildWidth='300px' >
                        <Box p={3}>
                            <Center>
                                <HStack
                                    m={5}
                                    marginBottom={0}
                                    alignItems="center">
                                    <Logo height={60} />
                                    <Heading
                                        as="h1"
                                        fontFamily='title.header'
                                        fontSize="3xl">{process.env.REACT_APP_TITLE}</Heading>
                                </HStack>
                            </Center>
                            <Center>
                                <Text>We are happy to see you!</Text>
                            </Center>
                            <Center>
                                <Box p={5} w='80%' maxW={400}>
                                    <form onSubmit={handleLoginSubmit(onSubmitLogin)}>
                                        <FormControl isInvalid={loginErrors.login_email} isDisabled={isLoginSubmitting || isLoading}>
                                            <Input id='login_email' type='email' placeholder="Email" marginTop={3} isRequired
                                                {...registerLogin('login_email', {
                                                    required: true,
                                                })} onChange={() => setIsLoginSuccess(false)}
                                                aria-invalid={loginErrors.login_email ? "true" : "false"} />
                                            <FormErrorMessage>
                                                {loginErrors.login_email && loginErrors.login_email.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={loginErrors.login_password} isDisabled={isLoginSubmitting || isLoading}>
                                            <Input id='login_password' type='password' placeholder="Password" marginTop={3} isRequired
                                                {...registerLogin('login_password', {
                                                    required: true,
                                                })}
                                                aria-invalid={loginErrors.login_password ? "true" : "false"} />
                                            <FormErrorMessage>
                                                {loginErrors.login_password && loginErrors.login_password.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <Text align='right'><Link fontSize='xs' as={NavLink} color='var(--chakra-colors-gray-500)' onClick={onRecoveryOpen}>Forget password?</Link></Text>
                                        {loginError !== null &&
                                            <Alert status='error' mt={3}>
                                                <AlertDescription>{loginError}</AlertDescription>
                                            </Alert>}
                                        {(loginError !== null && isLoginSuccess) &&
                                        <Button w='100%' marginTop={5} isLoading={isLoginSubmitting || isLoading} onClick={() => onConfirmationResendLinkClick()}>Resend email confirmation link</Button>}
                                        <Button type="submit" w='100%' marginTop={5} isLoading={isLoginSubmitting || isLoading}>Sign in</Button>
                                    </form>
                                </Box>
                            </Center>
                            <Center>
                                <Box p={5} w='80%' maxW={400}>
                                    <Stack>
                                        <Button variant='outline'
                                            onClick={() => getLoginUrl('google')}
                                            _hover={{ bg: 'none' }}
                                            _focus={{ bg: 'none' }}
                                            borderColor='var(--chakra-colors-gray-600)'
                                            isDisabled={isLoginSubmitting || isLoading}>
                                            <HStack minW='170px'>
                                                <GoogleAuth height={20} />
                                                <Text fontSize='sm' color='var(--chakra-colors-gray-500)'>Login with <b>Google</b></Text>
                                            </HStack>
                                        </Button>
                                        <Button variant='outline'
                                            onClick={() => getLoginUrl('facebook')}
                                            _hover={{ bg: 'none' }}
                                            _focus={{ bg: 'none' }}
                                            borderColor='var(--chakra-colors-gray-600)'
                                            isDisabled={isLoginSubmitting || isLoading}>
                                            <HStack minW='170px'>
                                                <Facebook height={20} />
                                                <Text fontSize='sm' color='var(--chakra-colors-gray-500)'>Login with <b>Facebook</b></Text>
                                            </HStack>
                                        </Button>
                                        <Button variant='outline'
                                            onClick={() => getLoginUrl('linkedin-openid')}
                                            _hover={{ bg: 'none' }}
                                            _focus={{ bg: 'none' }}
                                            borderColor='var(--chakra-colors-gray-600)'
                                            isDisabled={isLoginSubmitting || isLoading}>
                                            <HStack minW='170px'>
                                                <LinkedIn height={20} />
                                                <Text fontSize='sm' color='var(--chakra-colors-gray-500)'>Login with <b>LinkedIn</b></Text>
                                            </HStack>
                                        </Button>
                                    </Stack>
                                </Box>
                            </Center>
                            <Text color='var(--chakra-colors-gray-500)' align='center' fontSize='sm' marginTop={2}>Don't have account yet? <Link as={NavLink} onClick={onRegisterOpen} color="purple.500">Register</Link></Text>
                        </Box>
                        <Hide below='md'>
                            <Image src={LandingImage} objectFit="cover" height="100%" roundedBottomRight="8" roundedTopRight="8" />
                        </Hide>
                    </SimpleGrid>
                </AbsoluteCenter>
            </Flex>
            <Modal
                closeOnOverlayClick={false}
                isOpen={isRecoveryOpen}
                onClose={onRecoveryClose}
                motionPreset='slideInTop'
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <form onSubmit={handleRecoverySubmit(onSubmitRecovery)}>
                        <ModalHeader>Reset password</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl isInvalid={recoveryErrors.recovery_email}
                                            isDisabled={isRecoverySubmitting || isLoading}>
                                <Input type='recovery_email' placeholder='Email' isRequired
                                    {...registerRecovery('recovery_email', {
                                        required: true,
                                    })}
                                    aria-invalid={recoveryErrors.recovery_email ? "true" : "false"}
                                    autoComplete="recovery-email" />
                                <FormErrorMessage>
                                    {recoveryErrors.recovery_email && recoveryErrors.recovery_email.message}
                                </FormErrorMessage>
                            </FormControl>
                            {recoveryError !== null &&
                                <Alert status='error' mt={3}>
                                    <AlertDescription>{recoveryError}</AlertDescription>
                                </Alert>}
                        </ModalBody>

                        <ModalFooter>
                            <Button w='100%' type='submit' isLoading={isRecoverySubmitting}>
                                Send recovery link
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            <Modal
                closeOnOverlayClick={false}
                isOpen={isRegisterOpen}
                onClose={onRegisterClose}
                motionPreset='slideInTop'
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <form onSubmit={handleRegisterSubmit(onSubmitRegister)}>
                        <ModalHeader>Register</ModalHeader>
                        <ModalCloseButton isDisabled={isRegisterSubmitting || isLoading} />
                        <ModalBody pb={6}>
                            <FormControl isInvalid={registerErrors.register_name}
                                            isDisabled={isRegisterSubmitting || isLoading}>
                                <Input id='register_name' type='name' placeholder='Name' isRequired
                                    {...registerRegister('register_name', {
                                        required: true,
                                    })}
                                    aria-invalid={registerErrors.register_name ? "true" : "false"}
                                    autoComplete="new-name" />
                                <FormErrorMessage>
                                    {registerErrors.register_name && registerErrors.register_name.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={registerErrors.register_email}
                                            isDisabled={isRegisterSubmitting || isLoading}>
                                <Input id='register_email' type='email' placeholder='Email' isRequired mt={3}
                                    {...registerRegister('register_email', {
                                        required: true,
                                    })}
                                    aria-invalid={registerErrors.register_email ? "true" : "false"} 
                                    autoComplete="new-email" />
                                <FormErrorMessage>
                                    {registerErrors.register_email && registerErrors.register_email.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={registerErrors.register_password}
                                            isDisabled={isRegisterSubmitting || isLoading}>
                                <Input id='register_password' type='password' placeholder='Password' isRequired mt={3}
                                    {...registerRegister('register_password', {
                                        required: true,
                                        minLength: 8
                                    })}
                                    aria-invalid={registerErrors.recovery_email ? "true" : "false"}
                                    autoComplete="new-password" />
                                <FormErrorMessage>
                                    {registerErrors.register_password && registerErrors.register_password.message}
                                    {registerErrors.register_password && registerErrors.register_password.type === "minLength" && (
                                        <>Password must be at least 8 symbols length</>
                                    )}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={registerErrors.register_password2}
                                            isDisabled={isRegisterSubmitting || isLoading}>
                                <Input id='register_password2' type='password' placeholder='Repeat password' isRequired mt={3}
                                    {...registerRegister('register_password2', {
                                        required: true,
                                        validate: (val) => {
                                            if (watch('register_password') !== val) {
                                                return "Your passwords do no match";
                                            }
                                        },
                                    })}
                                    aria-invalid={registerErrors.register_password2 ? "true" : "false"}
                                    autoComplete="new-password-confirmation" />
                                <FormErrorMessage>
                                    {registerErrors.register_password2 && registerErrors.register_password2.message}
                                </FormErrorMessage>
                            </FormControl>
                            <Checkbox id='register_agree' mt={3}
                                {...registerRegister('register_agree', {
                                    required: true,
                                })}
                                aria-invalid={registerErrors.register_agree ? "true" : "false"}><Text fontSize="xs">I agree with <Link as={NavLink} color="purple.500" target='_blank' to='/public/page/terms_and_conditions'>Terms and Conditions</Link> and <Link as={NavLink} color="purple.500" target='_blank' to='/public/page/privacy_policy'>Privacy Policy</Link>.</Text></Checkbox>
                            {registerError !== null &&
                                <Alert status='error' mt={3}>
                                    <AlertDescription>{registerError}</AlertDescription>
                                </Alert>}
                        </ModalBody>

                        <ModalFooter>
                            <Button w='100%' type='submit' isLoading={isRegisterSubmitting} isDisabled={watch('register_agree') === false}>
                                Register
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal >

            <Modal
                closeOnOverlayClick={false}
                isOpen={isEmailConfirmationOpen}
                onClose={onEmailConfirmationClose}
                motionPreset='slideInTop'
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <ModalHeader>Email confirmation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Text>Please check your inbox for email confirmation details and confirm your email address.</Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button w='100%' onClick={() => onEmailConfirmationClose()}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}