import { TrackGoogleAnalyticsEvent } from "..";

const Category = "HISTORY";

const ViewHistoryLinkIntent = "VIEW_HISTORY";


const TrackViewHistoryLinkIntentEvent = () => {
    TrackGoogleAnalyticsEvent(Category,
      ViewHistoryLinkIntent);
}


export {TrackViewHistoryLinkIntentEvent};