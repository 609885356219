import { Grid, GridItem } from "@chakra-ui/react";
import Header from "../header/header";
import { Outlet } from "react-router-dom";
import Footer from "../footer/footer";


export const PublicStructure = () => {

    return (<div><Grid 
        templateAreas={`'header' 'main' 'footer'`} 
        templateColumns="auto"
        minH="100vh">
        <GridItem area="header">
            <Header />
        </GridItem>
        <GridItem as="main" area="main" p={{ base: 6, md: 8 }}>
            <Outlet />
        </GridItem>
        <GridItem area="footer">
            <Footer as="flex" bottom="0"/>
        </GridItem>
      </Grid></div>);
}