import { Link } from "react-router-dom"
import { Flex, Text, HStack, IconButton, Divider } from "@chakra-ui/react"
import Logo from "../controls/logo";
import * as Icons from 'react-feather';
import UserInfo from "../auth/userInfo";
import { useAuth } from "../../hooks/useAuth";

const Header = (props) => {
  const { user } = useAuth();

  return (
    <Flex
      direction="row"
      align="center"
      justify="space-between"
      wrap="wrap"
      minW={'100%'}
      px={6}
      pt={8}
      pb={4}
    >
      {props.showSidebarButton && (
          <IconButton
            icon={<Icons.Menu w={8} h={8} />}
            colorScheme="blackAlpha"
            variant="unstyled"
            onClick={props.onShowSidebar}
          />
        )}
      <Flex align="center">
        <Link to="/">
          <HStack>
            <Logo height={'44px'} />
            <Text fontFamily='title.header' fontSize="lg">{process.env.REACT_APP_TITLE}</Text>
          </HStack>
        </Link>
      </Flex>

      {(user && user.has_subscription === false) && (
      <UserInfo isLogoutOnly={true}/>)}
      <Divider mt={4}/>
    </Flex>
  )
}

export default Header