import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = () => {
  ReactGA4.initialize(process.env.REACT_APP_TRACKING_ID);
};
const SetGoogleAnalyticsUserId = (id) => {
  ReactGA4.set({ userId: id });
};

const TrackGoogleAnalyticsEvent = (
  category,
  event_name,
  label,
  data
) => {

  // let event_params = {
  //   category,
  //   label,
  //   ...data
  //   };

  ReactGA4.event(
    {
      action: event_name,
      category: category,
      label: label,
    },
    data
  );
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, SetGoogleAnalyticsUserId };