/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { SetGoogleAnalyticsUserId } from "../utils/google-analytics";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage("token", null);
  const [user, setUser] = useLocalStorage("user", null);

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setToken(data);
  };

  const updateUser = async (data) => {
    if (data)
    {
      SetGoogleAnalyticsUserId(data.id);
    }
    else
    {
      SetGoogleAnalyticsUserId(undefined);
    }
    setUser(data);
  }

  // call this function to sign out logged in user
  const logout = async () => {
    setToken(null);
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      token,
      login,
      logout,
      updateUser,
    }),
    [user, token]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};