export default function KebabMenu(props) {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g id="Kebab-Menu" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="Container" x="0" y="0" width="24" height="24">
                </rect>
                <path d="M12,6 C12.5522847,6 13,5.55228475 13,5 C13,4.44771525 12.5522847,4 12,4 C11.4477153,4 11,4.44771525 11,5 C11,5.55228475 11.4477153,6 12,6 Z" id="shape-03" stroke="#030819" strokeWidth="2" strokeLinecap="round" strokeDasharray="0,0">
                </path>
                <path d="M12,13 C12.5522847,13 13,12.5522847 13,12 C13,11.4477153 12.5522847,11 12,11 C11.4477153,11 11,11.4477153 11,12 C11,12.5522847 11.4477153,13 12,13 Z" id="shape-03" stroke="#030819" strokeWidth="2" strokeLinecap="round" strokeDasharray="0,0">
                </path>
                <path d="M12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 C11.4477153,18 11,18.4477153 11,19 C11,19.5522847 11.4477153,20 12,20 Z" id="shape-03" stroke="#030819" strokeWidth="2" strokeLinecap="round" strokeDasharray="0,0">
                </path>
            </g>
        </svg>
    );
};