import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import api from "./Api";
import { PageLoading } from '../components/controls/pageLoading';

function UserService() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [isError, setIsError] = useState(false);
    const initialized = useRef(false);
    const { updateUser, logout } = useAuth();
    
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            const getUser = () => {
                api.get(`/api/user`)
                    .then((response) => {
                        setLoading(false);
                        setIsError(false);
                        setData(response.data);
                    })
                    .catch((error) => {
                        setLoading(false);
                        setIsError(true);
                        setData(error);
                    });
            }

            getUser();
        }
    }, []);

    if (loading) {
        return <PageLoading />;
    }

    if (isError) {
        logout();
        return <Navigate to="/login" />;
    }
    updateUser(data);
    return <Navigate to="/" />;
}

export default UserService;