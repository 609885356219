import React, { useEffect, useRef, useState } from 'react';
import { PageLoading } from "../../components/controls/pageLoading";
import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";
import api from '../../services/Api';
import LogoutService from '../../services/LogoutService';

function Checkout() {
    const [loading, setLoading] = useState(true);
    const [isCleared, setIsCleared] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const initialized = useRef(false);
    const { updateUser } = useAuth();

    useEffect(() => {
        if (initialized.current !== true) {
            initialized.current = true;

            updateUser(null);
            api.get(`/api/user`)
                .then((response) => {
                    if (response.data.has_subscription === false) {
                        setIsLogout(true);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setIsLogout(true);
                    setLoading(false);
                });

            setIsCleared(true);
        }
    }, [updateUser]);

    if (loading) {
        return <PageLoading />;
    }
    
    if (isLogout) {
        return <LogoutService />;
    }

    if (!isCleared) {
        return <PageLoading />;
    }

    return <Navigate to="/" />
}
export default Checkout;