import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import api from "./Api";
import { PageLoading } from '../components/controls/pageLoading';

function LogoutService() {

    const [loading, setLoading] = useState(true);
    const { logout } = useAuth();
    
    useEffect(() => {
        api.get(`/api/auth/session/close`)
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <PageLoading />;
    }

    logout();
    return <Navigate to="/login" />;
}

export default LogoutService;