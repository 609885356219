import { AbsoluteCenter, Box, Spinner } from "@chakra-ui/react";

export const PageLoading = () => {

    return (
        <Box position='relative' h='300px' w='100%'>
            <AbsoluteCenter axis='both'>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='primary.500'
                    size='xl'
                />
            </AbsoluteCenter>
        </Box>
    );
};