import { FETCH_SEQUENCES_BEGIN, FETCH_SEQUENCES_SUCCESS, FETCH_SEQUENCES_FAILURE } from './actionTypes';

export const fetchSequencesBegin = () => ({
  type: FETCH_SEQUENCES_BEGIN
});

export const fetchSequencesSuccess = sequences => ({
  type: FETCH_SEQUENCES_SUCCESS,
  payload: { sequences }
});

export const fetchSequencesFailure = error => ({
  type: FETCH_SEQUENCES_FAILURE,
  payload: { error }
});