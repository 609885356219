import { PrivateStructure } from "../../components/layouts/PrivateStructure";
import { PublicStructure } from "../../components/layouts/PublicStructure";
import { useAuth } from "../../hooks/useAuth";

export const PublicPath = () => {
    const { user } = useAuth();
  
    if (!user) {
  
        return (<PublicStructure />);
    }
  
    return (<PrivateStructure />);
  };