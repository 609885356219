import { Alert, AlertIcon, AlertTitle, Box, Button, Center, Divider, Grid, GridItem, Heading, Input, Progress } from "@chakra-ui/react";
import { useState } from "react";
import api from "../../services/Api";
import { Navigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchSequences } from "../../redux/fetchSequences";

export default function ExplainSequence() {
    const dispatch = useDispatch();

    const [yogaQuery, setYogaQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [newSequence, setNewSequence] = useState({});
    const [error, setError] = useState('');

    const generateSequence = () => {
        setIsLoading(true);
        api.post(`/api/yoga/generate/query`, { query: yogaQuery })
            .then((response) => {
                getRunStatus(response.data.thread_id, response.data.id);
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                setError(error);
            });
    }

    const getRunStatus = (threadId, runId) => {
        api.get(`/api/yoga/generate/check/${threadId}/${runId}`)
            .then((response) => {
                if (response.data.completed_at === null) {
                    setTimeout(() => {
                        getRunStatus(threadId, runId);
                    }, 5000);
                }
                else {
                    getSequence(response.data.thread_id, runId);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                setError(error);
            });
    }

    const getSequence = (threadId, runId) => {
        api.get(`/api/yoga/generate/${threadId}/${runId}`)
            .then((response) => {
                setIsLoading(false);
                setIsError(false);
                setNewSequence(response.data);
            })
            .catch((e) => {
                setIsLoading(false);
                setIsError(true);
                setError(e);
            });
    }

    if (isLoading) {
        window.scrollTo(0, 0);
        return (
            <Center>
                <Box>
                    <Heading size='md' mb={5}>
                        Generating sequence... <br /> It can take several minutes
                    </Heading>
                    <Progress size='xs' isIndeterminate />
                </Box>
            </Center>
        );
    }

    if (!isLoading && Object.keys(newSequence).length !== 0) {
        dispatch(fetchSequences(10));
        return <Navigate to={`/sequence/history/${newSequence.id}`} />;
    }

    return (
        <Grid minH='100%' templateRows='auto 1fr' templateColumns='1'>
            <GridItem>
                <Heading>Yoga sequence generation prompt</Heading>
                {/* <Heading size='md' mb={5}>Get inspired by the yoga sequence! Yoga sequencing tool</Heading> */}
                <Divider my={8} />
                {isError && (
                    <Alert status='error' mb={5}>
                        <AlertIcon />
                        <AlertTitle>{error}</AlertTitle>
                    </Alert>
                )}
            </GridItem>
            <GridItem>
                <Input
                    value={yogaQuery}
                    onChange={(event) => setYogaQuery(event.target.value)}
                    placeholder='What you want to learn today?'
                    size='lg'
                />
                <Divider my={8} />
                <Button color='white' isLoading={isLoading} onClick={() => generateSequence()}>Generate</Button>
            </GridItem>
        </Grid>
    );
};
