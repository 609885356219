import { TrackGoogleAnalyticsEvent } from "..";

const Category = "PROFILE";

const SubscriptionPortButtonIntent = "SUBSCRIPTION_PORTAS";
const NewUpdatesCheckboxIntent = "NEWS_UPDATES";
const PromotionsCheckboxIntent = "PROMOTIONS";
const CommunityUpdateCheckboxIntent = "COMMUNITY_UPDATES";


const TrackSubscriptionPortButtonIntentEvent = () => {
    TrackGoogleAnalyticsEvent(Category,
      SubscriptionPortButtonIntent);
}

const TrackNewUpdatesCheckboxIntentEvent = () => {
    TrackGoogleAnalyticsEvent(Category,
      NewUpdatesCheckboxIntent);
}

const TrackPromotionsCheckboxIntentEvent = () => {
    TrackGoogleAnalyticsEvent(Category,
      PromotionsCheckboxIntent);
}

const TrackCommunityUpdateCheckboxIntentEvent = () => {
    TrackGoogleAnalyticsEvent(Category,
      CommunityUpdateCheckboxIntent);
}


export {TrackSubscriptionPortButtonIntentEvent,
  TrackNewUpdatesCheckboxIntentEvent,
  TrackPromotionsCheckboxIntentEvent,
  TrackCommunityUpdateCheckboxIntentEvent};