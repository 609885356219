import api from '../services/Api';
import { fetchSequencesBegin, fetchSequencesSuccess, fetchSequencesFailure } from './actions';

export function fetchSequences(limit = 0) {
  return dispatch => {
    dispatch(fetchSequencesBegin());
    return api.get(`/api/yoga/history/list/${limit}`)
    .then(response => dispatch(fetchSequencesSuccess(response.data)))
    .catch(error => dispatch(fetchSequencesFailure(error)));
  };
}