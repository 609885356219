import { MenuItem } from "@chakra-ui/react";
import LogoutService from "../../services/LogoutService";
import { useState } from "react";

export const LogoutMenuItem = (props) => {
    const [isLogout, setIsLogout] = useState(false);
    
    const handleLogout = () => {
        setIsLogout(true);
    };

    if (isLogout)
    {
        return <LogoutService />;
    }

    return (
        <MenuItem {...props} onClick={handleLogout}>Logout</MenuItem>
    );
};