import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Card, CardBody, CardHeader, Flex, HStack, Heading, IconButton, Spacer, Stack, Text, Tooltip } from "@chakra-ui/react";
import "./sequence.css";
import { useEffect, useRef, useState } from "react";
import api from "../../services/Api";
import { useParams } from "react-router-dom";
import { PageLoading } from "../../components/controls/pageLoading";
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import * as Icons from 'react-feather';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Logo from "../../components/controls/logo";
import FontSizeChanger from 'react-font-size-changer';
//import ReactToPrint from "react-to-print";
import { useWakeLock } from 'react-screen-wake-lock';

export default function Sequence() {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [sequence, setSequence] = useState({});
    const [fullscreenId, setFullscreenId] = useState(Math.random());
    const { id } = useParams();
    const initialized = useRef(0);
    const fullScreenHandle = useFullScreenHandle();
    const ref = useRef();
    const { isSupported, released, request, release } = useWakeLock();

    useEffect(() => {
        if (initialized.current !== id) {
            initialized.current = id;
            api.get(`/api/yoga/history/${id}`)
                .then((response) => {
                    setIsLoading(false);
                    setIsError(false);
                    setSequence(response.data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsError(true);
                    setSequence(error);
                });
        }
    });

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    if (isLoading) {
        return <PageLoading />;
    }

    if (isError) {
        return (<Alert status='error'>
            <AlertIcon />
            <AlertTitle>Cannot load data!</AlertTitle>
            <AlertDescription>{JSON.stringify(sequence)}</AlertDescription>
        </Alert>)
    }

    scrollToTop();

    return (
        <FullScreen handle={fullScreenHandle}>
            <Card mx={5} key={fullscreenId} ref={ref}>
                <CardHeader>
                    <HStack className='sequence-company' display="none">
                        <Logo height={'44px'} />
                        <Text fontFamily='title.header' fontSize="lg">{process.env.REACT_APP_TITLE}</Text>
                    </HStack>
                    <Flex justifyContent='space-between' direction={{ base: 'column', md: 'row' }}>
                        {fullScreenHandle.active ?
                            (<Flex>
                                <HStack className='print-hide'>
                                    <Logo height={'44px'} />
                                    <Text fontFamily='title.header' fontSize="lg">{process.env.REACT_APP_TITLE}</Text>
                                </HStack>
                                <Spacer className='print-hide' />
                            </Flex>)
                            : null
                        }
                        <Flex>
                            <Heading fontSize='2xl' pt='8px'>{sequence.title}</Heading>
                        </Flex>
                        <Flex className='print-hide'>
                            <Box display="flex" justifyContent='end'>
                            {isSupported &&
                            <Tooltip hasArrow 
                                    label={released === false ? 
                                        <Stack p={2}>
                                            <Text lineHeight={0.8}>Rest mode. Allow your</Text>
                                            <Text lineHeight={0.8}>screen to go dark.</Text>
                                        </Stack> 
                                        : 
                                        <Stack p={2}>
                                            <Text lineHeight={0.8}>Yoga mode. Prevent your</Text>
                                            <Text lineHeight={0.8}>screen from going dark.</Text>
                                        </Stack>} fontSize='md'>
                                <IconButton
                                    icon={released === false ? <Icons.Sun w={8} h={8} /> : <Icons.Moon w={8} h={8} />}
                                    colorScheme="blackAlpha"
                                    variant="unstyled"
                                    onClick={() => (released === false ? release() : request())}
                                />
                            </Tooltip>}
                            {/* <ReactToPrint
                                bodyClass="print-sequence"
                                content={() => ref.current}
                                trigger={() => (
                                    <IconButton
                                        icon={<Icons.Printer w={8} h={8} />}
                                        colorScheme="blackAlpha"
                                        variant="unstyled"
                                        onClick={fullScreenHandle.enter}
                                    />
                                )}
                            /> */}
                            {fullScreenHandle.active ?
                                (<>
                                    <FontSizeChanger
                                        targets={['.sequence_content']}
                                        onChange={(element, newValue, oldValue) => {

                                        }}
                                        options={{
                                            stepSize: 1,
                                            range: 3
                                        }}
                                        customButtons={{
                                            up: <Icons.ZoomIn w={8} h={8} />,
                                            down: <Icons.ZoomOut w={8} h={8} />,
                                            style: {
                                                border: "0px",
                                                marginTop: "8px"
                                            }
                                        }}
                                    />
                                    <Tooltip label='Turn off fullscreen' fontSize='md'>
                                    <IconButton
                                        icon={<Icons.Minimize w={8} h={8} />}
                                        colorScheme="blackAlpha"
                                        variant="unstyled"
                                        onClick={() => {
                                            fullScreenHandle.exit();
                                            setFullscreenId(Math.random());
                                        }}
                                    />
                                    </Tooltip></>)
                                : (
                                    <Tooltip label='Turn on fullscreen' fontSize='md'>
                                        <IconButton
                                    icon={<Icons.Maximize w={8} h={8} />}
                                    colorScheme="blackAlpha"
                                    variant="unstyled"
                                    onClick={fullScreenHandle.enter}
                                />
                                </Tooltip>)
                            }
                            </Box>
                        </Flex>
                    </Flex>
                </CardHeader>

                <CardBody className='sequence_body' px={10}>
                    {Object.keys(sequence).length !== 0 && (
                        <Stack className="new_sequence">
                                            <Prose className="sequence_content">
                                                <div dangerouslySetInnerHTML={{ __html: sequence.html_message }} />
                                            </Prose>
                        </Stack>
                    )}
                </CardBody>
            </Card>
        </FullScreen>
    );
};