import React, { useEffect, useState } from 'react';
import api from "../../services/Api";
import { PageLoading } from "../../components/controls/pageLoading";

export default function Portal() {
    const [isLoading, setIsLoading] = useState(true);
    const [session, setSession] = useState({});
    
    useEffect(() => {
            function loadPortalSession()
            {
                api.get(`/api/portal/session/expired`)
                    .then((response) => {
                        setIsLoading(false);
                        setSession(response.data);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        console.error(`Could not load subscription data. Please contact our support <b><a href='mailto:support@greatinnature.com'>support@greatinnature.com</a></b>`);
                    });
            }

            if (!session.url)
            {
                loadPortalSession();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    if (isLoading) {
        return <PageLoading />;
    }


    window.location.href = session.url;
}