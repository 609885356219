import {
    Avatar,
    Box,
    Flex,
    Heading,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
} from "@chakra-ui/react"
import { useAuth } from "../../hooks/useAuth";
import KebabMenu from "./kebab";
import * as Icons from 'react-feather';
import { LogoutMenuItem } from "../controls/logout";
import { NavLink } from "react-router-dom";

export default function UserInfo(props) {
    const { user } = useAuth();

    const handleProfileNavigate = () => {
        if (props.onClickItem)
        {
            props.onClickItem();
        }
    }

    if (user === null)
    {
        return <div />;
    }

    return (
        <Flex spacing='4'>
            { props.isLogoutOnly !== true && (
            <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                <Avatar
                    name={user.name}
                    src={user.avatar}
                    size="sm"
                    rounded="sm" />

                <Box>
                    <Heading size='xs' width='190px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>{user.name}</Heading>
                    <Text fontSize='xs'>{user.is_trial ? 'Free Trial' : 'Premium plan'}</Text>
                </Box>
            </Flex>)}
            <Menu on>
                { props.isLogoutOnly === true && (<MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<Avatar
                        name={user.name}
                        src={user.avatar}
                        size="sm"
                        rounded="sm" />}
                    variant='ghost'
                />)}    
                { props.isLogoutOnly !== true && (<MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<KebabMenu />}
                    variant='ghost'
                />)}    
                <MenuList>
                    { props.isLogoutOnly !== true && (
                    <MenuItem 
                    onClick={() => handleProfileNavigate()}
                    as={NavLink}
                    icon={<Icons.BarChart2 />} 
                    px={3} 
                    to="/user/profile">Profile</MenuItem>)}
                    { props.isLogoutOnly === true && (
                    <MenuItem 
                    disabled
                    px={3}>{user.name}</MenuItem>)}
                    <MenuDivider />
                    <LogoutMenuItem icon={<Icons.LogOut />} />
                </MenuList>
            </Menu>
        </Flex>
    )
}