import React, { Component } from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import { Routes, Route } from "react-router-dom"
import Login from './routes/user/Login';
import Home from './routes/user/Home';
import AuthCallback from './services/AuthCallback';
import { AuthProvider } from "./hooks/useAuth";
import NewSequence from './routes/yoga/newSequence';
import UserService from './services/UserService';
import Sequence from './routes/yoga/sequence';
import { Theme } from './resources/theme/theme';
import ExplainSequence from './routes/yoga/explainSequence';
import Profile from './routes/user/Profile';
import Subscription from './routes/user/Subscription';
import Checkout from './routes/user/Checkout';
import Page from './routes/public/Page';
import { PublicPath } from './routes/paths/PublicPath';
import { UnprotectedPath } from './routes/paths/UnprotectedPath';
import { ProtectedPath } from './routes/paths/ProtectedPath';
import { AllSequences } from './routes/yoga/allSequences';
import InitializeGoogleAnalytics from './utils/google-analytics';
import ResetPassword from './components/auth/resetPassword';

class App extends Component {
  componentDidMount() {
    InitializeGoogleAnalytics();
  }

  render() {
    return (
      <ChakraProvider theme={Theme} >
        <AuthProvider>
          <Routes>
            <Route element={<PublicPath />}>
              <Route path="/public/page/:id" element={<Page />} />
            </Route>
            <Route element={<UnprotectedPath />}>
              <Route path="/login" element={<Login />} />
              <Route path="/auth/:id" element={<AuthCallback />} />
              <Route path="/password-reset/:token" element={<ResetPassword />} />
            </Route>
            <Route element={<ProtectedPath />}>
              <Route path="/" element={<Home />} />
              <Route path="/sequence/new" element={<NewSequence />} />
              <Route path="/sequence/explain" element={<ExplainSequence />} />
              <Route path="/auth/user" element={<UserService />} />
              <Route path="/sequence/history/:id" element={<Sequence />} />
              <Route path="/user/profile" element={<Profile />} />
              <Route path="/user/subscription" element={<Subscription />} />
              <Route path="/user/checkout/finalize" element={<Checkout />} />
              <Route path="/sequence/history" element={<AllSequences />} />
            </Route>
          </Routes>
        </AuthProvider>
      </ChakraProvider>
    )
  }
}

export default App;
