import { FETCH_SEQUENCES_BEGIN, FETCH_SEQUENCES_SUCCESS, FETCH_SEQUENCES_FAILURE } from './actionTypes';

const initialState = {
  items: [],
  loading: false,
  error: null
};

export default function sequenceReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_SEQUENCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_SEQUENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.sequences
      };

    case FETCH_SEQUENCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    default:
      return state;
  }
}