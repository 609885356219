import axios from 'axios';

const api = axios.create({
   baseURL: process.env.REACT_APP_API_HOST,
   withCredentials: true,
   withXSRFToken: true,
});

// Add an interceptor for all requests
api.interceptors.request.use(config => {
   const value = window.localStorage.getItem("token");
   const token = (value) ? JSON.parse(value) : null;

   // Retrieve the access token from React state or a state management system
   const accessToken = (typeof (token) !== 'undefined' && token !== null) ? `${token.token_type} ${token.access_token}` : "";

   // Add the access token to the Authorization header
   config.headers.Authorization = accessToken;
   config.headers.Accept = "application/json";

   return config;
});

api.interceptors.response.use((response) => response, (error) => {
   if (error.response.status === 401) {
      window.localStorage.setItem("token", null);
      window.localStorage.setItem("user", null);
      window.location = '/login';
      return Promise.reject(error.response.data.message);
   }
   else
   {
      return Promise.reject(error.response.data.message);
   }
 });

export default api;