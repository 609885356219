import { ColorModeScript, Link } from '@chakra-ui/react';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, NavLink } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import CookieConsent from "react-cookie-consent";

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  // <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ColorModeScript />
        <App />
        <CookieConsent
            style={{ background: "#2B373B" }}
            buttonStyle={{ background: "var(--chakra-colors-primary-500)",
              color: "var(--chakra-colors-white)",
              borderRadius: "var(--chakra-radii-md)"   }}>
              This website uses cookies to provide you with a safer and more personalized experience. By accepting, you agree to the use of cookies for ads and analytics, in line with our <Link color="var(--chakra-colors-purple-500)" as={NavLink} to="/public/page/cookie_policy">Cookie Policy</Link>.
        </CookieConsent>
      </BrowserRouter>
    </Provider>
  //</StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
