import { Link, Table, TableContainer, Tbody, Td, Tr, Alert, AlertIcon, AlertTitle, Text, Box, AlertDescription } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { PageLoading } from "../../pageLoading";
import { NavLink } from "react-router-dom";
import { fetchSequences } from "../../../../redux/fetchSequences";

export default function History({limit = 0}) {
    const dispatch = useDispatch();
    const sequences = useSelector(state => state.items);
    const isLoading = useSelector(state => state.loading);
    const error = useSelector(state => state.error);
    const width = (limit === 0) ? '100%' : '260px';

    useEffect(() => {
        dispatch(fetchSequences(limit));
    }, [dispatch, limit]);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    const historyItem = (sequence, index) => (
        <Tr key={index}>
            <Td p={0}>
                <Link
                    display="block"
                    as={NavLink}
                    to={`/sequence/history/${sequence.id}`}
                    _focus={{ bg: "primary.100" }}
                    _hover={{
                        bg: "primary.200"
                    }}
                    _activeLink={{ bg: "primary.500", color: "white" }}
                    w="full"
                    borderRadius="md"
                    p={3}>
                    <Text fontSize='sm' width={`${width}`} overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
                        {(sequence.title.trim === "" || sequence.title.length === 0) ?
                            moment(sequence.created_at).format('MMMM Do YYYY, h:mm:ss a')
                            :
                            sequence.title
                        }
                    </Text>
                </Link>
            </Td>
        </Tr>
    );

    if (isLoading) {
        return <PageLoading />;
    }

    if (error) {
        return (<Alert status='error'>
            <AlertIcon />
            <AlertTitle>Cannot load data!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>)
    }

    if (Object.keys(sequences).length !== 0) {
        var data = sequences;
        if (limit > 0) {
            data = sequences.slice(0, 10);
        }
    }


    if (limit === 0) {
        scrollToTop();
    }

    return (
        <Box>
            {data && (
                <TableContainer>
                    <Table variant='simple'>
                        <Tbody>
                            {
                                data
                                    .sort((a, b) => a.created_at < b.created_at ? 1 : -1)
                                    .map((sequence, index) => historyItem(sequence, index))}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};