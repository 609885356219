import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Heading, Input, Stack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import api from "../../../../services/Api";
import { PageLoading } from "../../pageLoading";

export default function YogaStylesSelection( {onSelectYogaCriterias, onSelectionChanged} ) {
    const [yogaCriterias, setYogaCriterias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [isError, setIsError] = useState(false);
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            function getYogaCriterias() {
                api.get(`/api/yoga/criterias`)
                    .then((response) => {
                        setLoading(false);
                        setIsError(false);
                        setData(response.data);
                    })
                    .catch((error) => {
                        setLoading(false);
                        setIsError(true);
                        setData(error);
                    });
            };

            getYogaCriterias();
        }
    }, []);

    const removeTypes = (types) => {
        const selectedTypes = yogaCriterias.filter((e) => e.parents.some(parent => types.some(t => parent.parentCriteriaId.toString() === t.id.toString())));
        if (Object.keys(selectedTypes).length === 0) {
            return yogaCriterias;
        }
        const removedChildren = removeTypes(selectedTypes);
        return removedChildren.filter((e) =>
           !(selectedTypes.find((t) => t.id.toString() === e.id.toString())));
    }

    const handleTypeChange = (type) => {
        const removedChildren = removeTypes(yogaCriterias.filter((e) => e.categoryId === type.categoryId));
        const updatedCriterias = removedChildren.filter((e) => e.categoryId !== type.categoryId);
        updatedCriterias.push(type);
        setYogaCriterias(updatedCriterias);
        onSelectYogaCriterias(updatedCriterias);
    }

    const handleInputTypeChange = (type, value) => {
        const typeWithValue = {
            ...type,
            "input_value": value
        };
        handleTypeChange(typeWithValue);
    }

    const getCategoryForDisplay = (category) => {
        var result = null;
        var criterias = [];
        category.criterias.map(function (criteria, idx) {
            if (Object.keys(criteria.parents).length === 0 ||
                yogaCriterias.some(parent => criteria.parents.some((em) => parent.id.toString() === em.parentCriteriaId.toString()))) {

                var findResults = yogaCriterias.find((c) => c.id.toString() === criteria.id.toString());
                var findResult = (findResults) ? findResults.input_value : "";

                criterias.push({
                    "id": criteria.id,
                    "name": criteria.name,
                    "categoryId": criteria.categoryId,
                    "is_input": criteria.is_input,
                    "input_value": findResult,
                    "is_selected": (findResults) ? true : false,
                    "order": category.order,
                    "parents": criteria.parents,
                });
            }
            return criterias;
        });
        if (Object.keys(criterias).length !== 0) {
            result = {
                "id": category.id,
                "name": category.name,
                "enabled": category.enabled,
                "order": category.order,
                "criterias": criterias,
            };
        }

        return result;
    }

    if (loading) {
        return <PageLoading />;
    }

    if (isError) {
        return (<Alert status='error'>
            <AlertIcon />
            <AlertTitle>Cannot load data!</AlertTitle>
            <AlertDescription>{JSON.stringify(data)}</AlertDescription>
        </Alert>)
    }

    onSelectionChanged(true);

    return (
        <Stack>
            {data.sort((a, b) => a.order > b.order ? 1 : -1).map(function (category, i) {
                const filteredCategory = getCategoryForDisplay(category);
                if (filteredCategory && filteredCategory.criterias && filteredCategory.criterias.every(function(e){ return (e.is_selected === false && !e.input_value) }))
                {
                    onSelectionChanged(false);
                }
                if (filteredCategory) {
                    return (
                        <Stack key={i}>
                            <Heading size='sm'>{filteredCategory.name}</Heading>
                            <Box>

                                {filteredCategory.criterias.map(function (criteria, idx) {
                                    if (criteria.is_input === 0) {
                                        if (!criteria.is_selected) {
                                            return (<Button colorScheme="secondary" key={idx} m={1} isDisabled={false} onClick={() => handleTypeChange(criteria)} >{criteria.name}</Button>)
                                        }
                                        else {
                                            return (<Button key={idx} m={1} colorScheme="secondary" isDisabled={true} onClick={() => handleTypeChange(criteria)} >{criteria.name}</Button>)
                                        }
                                    }
                                    return null;
                                })}
                            </Box>
                            {filteredCategory.criterias.map(function (criteria, idx) {
                                if (criteria.is_input === 1) {
                                    return (<Input key={idx}
                                        placeholder={criteria.name}
                                        value={criteria.input_value}
                                        onChange={(event) => handleInputTypeChange(criteria, event.target.value)}
                                        size='lg'
                                    />)
                                }
                                return null;
                            }
                            )}
                        </Stack>)
                }
                return null;
            })}
        </Stack>
    );
};