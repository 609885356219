import { AbsoluteCenter, Alert, AlertDescription, Box, Button, Center, Flex, FormControl, FormErrorMessage, Heading, Hide, HStack, Image, Input, SimpleGrid, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import api from "../../services/Api";
import LandingImage from "../../resources/images/landing.webp"
import Logo from "../controls/logo";

function ResetPassword() {
    const { token } = useParams();
    // eslint-disable-next-line
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [error, setError] = useState(null);
    const [isChanged, setIsChanged] = useState(false);

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
        watch
    } = useForm()

    const { onClose } = useDisclosure()
    const csrf = () => api.get('/sanctum/csrf-cookie');

    const onSubmit = async (values) => {
        try {
            setError(null);
            await csrf();
            await api.post(`/reset-password`, { email: searchParams.get('email'), token: token, password: values.password, password_confirmation: values.password2 });

            onClose();
            reset();
            setIsChanged(true);
        }
        catch (e) {
            setError(e);
        }
    }

    if (isChanged) {
        return <Navigate to="/" />;
    }

    if (token === null || searchParams.get('email') === null) {
        console.log('Missing required data');
        return <Navigate to="/" />;
    }

    return (
        <>
            <Flex
                direction="column"
                align="center"
                minH={'100vh'}
                m="0 auto"

                bg="#f0f2f5"
                color="chakra-body-text"
            >
                <AbsoluteCenter bgColor="white" width="80%" rounded={8}>
                    <SimpleGrid columns={2} shadow="dark-lg" rounded={8} minChildWidth='300px' >
                        <Box p={3}>
                            <Center>
                                <HStack
                                    m={5}
                                    marginBottom={0}
                                    alignItems="center">
                                    <Logo height={60} />
                                    <Heading
                                        as="h1"
                                        fontFamily='title.header'
                                        fontSize="3xl">{process.env.REACT_APP_TITLE}</Heading>
                                </HStack>
                            </Center>
                            <Center>
                                <Text>We are happy to see you!</Text>
                            </Center>
                            <Center>
                                <Box p={5} w='80%' maxW={400}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <FormControl isInvalid={errors.password}>
                                            <Input id='password' type='password' placeholder='Password' isRequired mt={3}
                                                {...register('password', {
                                                    required: true,
                                                })}
                                                aria-invalid={errors.password ? "true" : "false"} />
                                            <FormErrorMessage>
                                                {errors.password && errors.password.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={errors.password2}>
                                            <Input id='password2' type='password' placeholder='Repeat password' isRequired mt={3}
                                                {...register('password2', {
                                                    required: true,
                                                    validate: (val) => {
                                                        if (watch('password') !== val) {
                                                            return "Your passwords do no match";
                                                        }
                                                    },
                                                })}
                                                aria-invalid={errors.password2 ? "true" : "false"} />
                                            <FormErrorMessage>
                                                {errors.password2 && errors.password2.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                        {error !== null &&
                                            <Alert status='error' mt={3}>
                                                <AlertDescription>{error}</AlertDescription>
                                            </Alert>}
                                        <Button w='100%' mt={5} type='submit' isLoading={isSubmitting}>
                                            Update password
                                        </Button>
                                    </form>
                                </Box>
                            </Center>
                        </Box>
                        <Hide below='md'>
                            <Image src={LandingImage} objectFit="cover" height="100%" roundedBottomRight="8" roundedTopRight="8" />
                        </Hide>
                    </SimpleGrid>
                </AbsoluteCenter>
            </Flex>
        </>
    )
}

export default ResetPassword;