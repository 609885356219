import { AbsoluteCenter, Alert, AlertIcon, AlertTitle, Box, Button, Divider, Grid, GridItem, Heading, Progress } from "@chakra-ui/react";
import YogaStylesSelection from "../../components/controls/yoga/sequence/yogaStylesSelection";
import { useState } from "react";
import api from "../../services/Api";
import { Navigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchSequences } from "../../redux/fetchSequences";
import { TrackSequenceSequenceGenerateFailureEvent, TrackSequenceSequenceGenerateSuccessEvent } from "../../utils/google-analytics/events/sequenceForm";

export default function NewSequence() {
    const dispatch = useDispatch();

    const [yogaCriterias, setYogaCriterias] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const [newSequence, setNewSequence] = useState({});
    const [isScrollTopUsed, setIsScrollTopUsed] = useState(false);
    const [isGenerateEnabled, setIsGenerateEnabled] = useState(false);


    const handleYogaCriterias = (criterias) => {
        setYogaCriterias(criterias);
    }

    const handleSelectionChanged = (isAllSelected) => {
        setIsGenerateEnabled(isAllSelected);
    }

    const generateSequence = () => {
        setIsScrollTopUsed(false);
        const payload = yogaCriterias.map(criteria => ({
            "id": criteria.id,
            "name": criteria.name,
            "order": criteria.order,
            "input_value": criteria.input_value
        }));
        setIsLoading(true);
        api.post(`/api/yoga/generate`, { criterias: payload })
            .then((response) => {
                TrackSequenceSequenceGenerateSuccessEvent(yogaCriterias);
                getRunStatus(response.data.queue_id, response.data.id);
            })
            .catch((error) => {
                TrackSequenceSequenceGenerateFailureEvent(yogaCriterias, error);
                setTimeout(() => {
                    setIsLoading(false);
                    setIsError(true);
                    setError(error);
                 }, 1000);
            });
    }

    const getRunStatus = (threadId, runId) => {
        api.get(`/api/yoga/generate/check/${threadId}/${runId}`)
            .then((response) => {
                if (!response.data.is_ready) {
                    setTimeout(() => {
                        getRunStatus(threadId, runId);
                    }, 5000);
                }
                else {
                    setIsLoading(false);
                    setIsError(false);
                    setNewSequence(response.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                setError(error);
            });
    }

    const scrollToTop = () => {
        if (isScrollTopUsed)
        {
            return;
        }
        setIsScrollTopUsed(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    if (isLoading) {
        scrollToTop();
        return (
            <Box position='relative' w='100%' h='300px'>
            <AbsoluteCenter>
                <Box>
                    <Heading size='md' mb={5}>
                        Generating sequence... <br /> It can take several minutes
                    </Heading>
                    <Progress size='xs' isIndeterminate />
                </Box>
            </AbsoluteCenter>
            </Box>
        );
    }

    if (Object.keys(newSequence).length !== 0) {
        dispatch(fetchSequences(10));
        return <Navigate to={`/sequence/history/${newSequence.id}`} />;
    }
    scrollToTop();

    return (
        <Grid minH='100%' templateRows='auto 1fr' templateColumns='1'>
            <GridItem>
                <Heading>Yoga sequence generation wizard</Heading>
                {/* <Heading size='md' mb={5}>Get inspired by the yoga sequence! Yoga sequencing tool</Heading> */}
                <Divider my={8} />
                {isError && (
                    <Alert status='error'>
                        <AlertIcon />
                        <AlertTitle>{error}</AlertTitle>
                    </Alert>
                )}
            </GridItem>
            <GridItem>
                <YogaStylesSelection onSelectYogaCriterias={handleYogaCriterias} onSelectionChanged={handleSelectionChanged} />
                <Divider my={8} />
                <Button color='white' isDisabled={!isGenerateEnabled} isLoading={isLoading} onClick={() => generateSequence()}>Generate</Button>
            </GridItem>
        </Grid>
    );
};
