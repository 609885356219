import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PageLoading } from "../../components/controls/pageLoading";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Stack } from "@chakra-ui/react";
import api from "../../services/Api";
import { Prose } from '@nikolovlazar/chakra-ui-prose';

export default function Page() {
    const { id } = useParams();
    const initialized = useRef('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState({});

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    useEffect(() => {
        if (initialized.current !== id) {
            initialized.current = id;
            api.get(`/api/public/pages/${id}`)
                .then((response) => {
                    setIsLoading(false);
                    setIsError(false);
                    setPage(response.data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsError(true);
                    setError(error);
                });
        }
    });

    if (isLoading) {
        return <PageLoading />;
    }

    if (isError) {
        return (<Alert status='error'>
            <AlertIcon />
            <AlertTitle>Cannot load data!</AlertTitle>
            <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>)
    }

    scrollToTop();
    
    return (
        <Stack>
            <Prose className="sequence_content">
                <div dangerouslySetInnerHTML={{ __html: page.body }} />
            </Prose>
        </Stack>
    )
}