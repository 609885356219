import {
  Divider,
  Flex,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react';
import { Instagram, Facebook } from 'react-feather';
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <Flex
      direction="row"
      align="center"
      justify="space-between"
      wrap="wrap"
      mb={0}
      p={10} 
      minW={'100%'}
      bg="gray.50"
    >
      <HStack my={4}>
        <Link as={NavLink} mr={4} fontSize='sm' to='/public/page/privacy_policy'>Privacy policy</Link>
        <Link as={NavLink} fontSize='sm' to='/public/page/terms_and_conditions'>Terms and Conditions</Link>
      </HStack>
      <Link as='a' fontSize='sm' my={4} href="mailto:support@greatinnature.com">support@greatinnature.com</Link>
      <Divider />
      <HStack my={4}>
        <Text fontSize="sm">Copyright © 2024</Text>
        <Text fontSize="sm">{process.env.REACT_APP_TITLE}</Text>
      </HStack>

      <HStack my={4}>
        <Text fontSize="sm">Follow us</Text>
        <Link as={NavLink} target='_blank' to='https://www.instagram.com/greatinnature/'> <Instagram/></Link>
        <Link as={NavLink} target='_blank' to='https://www.facebook.com/profile.php?id=61558387345601'> <Facebook/></Link>
        {/* <Youtube /> */}
      </HStack>
    </Flex>
  );
}