import { TrackGoogleAnalyticsEvent } from "..";

const LoginFormCategory = "USER_LOGIN";

const LoginFormLoginClick = "button click";
const LoginFormProviderRedirectSuccess = "oAuth redirect success";
const LoginFormProviderRedirectError = "oAuth redirect error";
const LoginFormTokenGet = "get token";
const LoginFormLoginSuccess = "login successful";
const LoginFormLoginFailure = "login fail";
const LoginFormRegisterSuccess = "register successful";
const LoginFormRegisterFailure = "register fail";

const TrackLoginButtonClickEvent = (provider) => {
    TrackGoogleAnalyticsEvent(LoginFormCategory,
                              LoginFormLoginClick,
                              provider);
}
const TrackLoginProviderRedirectSuccessEvent = (provider) => {
    TrackGoogleAnalyticsEvent(LoginFormCategory,
                              LoginFormProviderRedirectSuccess,
                              provider);
}
const TrackLoginProviderRedirectErrorEvent = (provider, error) => {
    TrackGoogleAnalyticsEvent(LoginFormCategory,
                              LoginFormProviderRedirectError,
                              provider,
                              {error: error});
}
const TrackLoginTokenGetEvent = (provider) => {
    TrackGoogleAnalyticsEvent(LoginFormCategory,
                            LoginFormTokenGet,
                              provider);
}
const TrackLoginLoginSuccessEvent = (provider) => {
    TrackGoogleAnalyticsEvent(LoginFormCategory,
                            LoginFormLoginSuccess,
                              provider);
}
const TrackLoginLoginFailureEvent = (provider, error) => {
    TrackGoogleAnalyticsEvent(LoginFormCategory,
                            LoginFormLoginFailure,
                              provider,
                              {error: error});
}
const TrackLoginRegisterSuccessEvent = (provider) => {
    TrackGoogleAnalyticsEvent(LoginFormCategory,
                            LoginFormRegisterSuccess,
                              provider);
}
const TrackLoginRegisterFailureEvent = (provider, error) => {
    TrackGoogleAnalyticsEvent(LoginFormCategory,
                            LoginFormRegisterFailure,
                              provider,
                              {error: error});
}


export {TrackLoginButtonClickEvent,
    TrackLoginProviderRedirectSuccessEvent,
    TrackLoginProviderRedirectErrorEvent,
    TrackLoginTokenGetEvent,
    TrackLoginLoginSuccessEvent,
    TrackLoginLoginFailureEvent,
    TrackLoginRegisterSuccessEvent,
    TrackLoginRegisterFailureEvent};