import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Navigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import api from "./Api";
import { PageLoading } from '../components/controls/pageLoading';
import { TrackLoginLoginFailureEvent, TrackLoginLoginSuccessEvent, TrackLoginRegisterSuccessEvent, TrackLoginTokenGetEvent } from '../utils/google-analytics/events/loginForm';

function AuthCallback() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [isError, setIsError] = useState(false);
    const initialized = useRef(false);
    const location = useLocation();
    const { login } = useAuth();
    const { id } = useParams();

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            TrackLoginTokenGetEvent(id);

            const getToken = () => {
                api.get(`/api/auth/callback/${id}${location.search}`)
                    .then((response) => {
                        setLoading(false);
                        setIsError(false);
                        setData(response.data);
                        if (response.data.created)
                        {
                            TrackLoginRegisterSuccessEvent(id);
                        }
                        else
                        {
                            TrackLoginLoginSuccessEvent(id);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        setIsError(true);
                        TrackLoginLoginFailureEvent(id, error);
                    });
            }

            getToken();
        }
    }, [location.search, id]);

    if (loading) {
        return <PageLoading />;
    }

    if (isError) {
        return <Navigate to="/login" />;
    }

    login(data);
    return <Navigate to="/auth/user" />;
}

export default AuthCallback;