import { TrackGoogleAnalyticsEvent } from "..";

const Category = "SEQUENCE";

const NewSequenceButtonClick = "SEQUENCE_WIZARD";
const VinyasaSequenceGenerate = "VINYASA_YOGA_SEQUENCE_CREATED";
const YinSequenceGenerate = "YIN_YOGA_SEQUENCE_CREATED";
const RestorativeSequenceGenerate = "RESTORATIVE_YOGA_SEQUENCE_CREATED";
const HathaSequenceGenerate = "HATHA_YOGA_SEQUENCE_CREATED";

const SequenceGenerateSuccess = "success";
const SequenceGenerateFailure = "failure";


const TrackSequenceNewSequenceButtonIntentEvent = () => {
  TrackGoogleAnalyticsEvent(Category,
    NewSequenceButtonClick);
}
const TrackSequenceSequenceGenerateSuccessEvent = (criterias) => {
  var type = GetYogaTypeEventName(criterias);
  if (type === null) {
    return;
  }

  TrackGoogleAnalyticsEvent(Category,
    type,
    SequenceGenerateSuccess,
    GetYogaCriterias(criterias));
}
const TrackSequenceSequenceGenerateFailureEvent = (criterias, error) => {
  var type = GetYogaTypeEventName(criterias);
  if (type === null) {
    return;
  }

  TrackGoogleAnalyticsEvent(Category,
    type,
    SequenceGenerateFailure,
    [...GetYogaCriterias(criterias), {error: error}]);
}

const GetYogaTypeEventName = (criterias) => {
  if (criterias.find((criteria) => criteria.id === 1)) {
    return VinyasaSequenceGenerate;
  }
  if (criterias.find((criteria) => criteria.id === 2)) {
    return YinSequenceGenerate;
  }
  if (criterias.find((criteria) => criteria.id === 3)) {
    return RestorativeSequenceGenerate;
  }
  if (criterias.find((criteria) => criteria.id === 4)) {
    return HathaSequenceGenerate;
  }
  return null;
}

const GetYogaCriterias = (criterias) => {
  return criterias.map(criteria => ({
    [criteria.categoryId] : criteria.input_value || criteria.name
  }));
}

export {
  TrackSequenceNewSequenceButtonIntentEvent,
  TrackSequenceSequenceGenerateSuccessEvent,
  TrackSequenceSequenceGenerateFailureEvent
};