import { Divider, Flex, Link, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export default function TextDivider({ label, viewAllPath, viewAllClick }) {
    return (
        <Flex align="center">
            <Divider />
            <Text padding="2" whiteSpace="nowrap">{label}</Text>
            <Divider />
            {viewAllPath && (
                <Link pl={2} as={NavLink} to={viewAllPath} onClick={() => { viewAllClick()}} color="purple.500" fontSize="xs" style={{ whiteSpace: 'nowrap' }}>View all</Link>
            )}
        </Flex>
    );
}