import {
    List,
    ListItem,
    Icon,
    Flex,
    Text,
    Link,
    Button,
    Divider,
} from "@chakra-ui/react";
import * as Icons from 'react-feather';
import { NavLink } from "react-router-dom";
import History from "../controls/yoga/history/history";
import UserInfo from "../auth/userInfo";
import TextDivider from "../controls/textDivider";
import { TrackSequenceNewSequenceButtonIntentEvent } from "../../utils/google-analytics/events/sequenceForm";
import { TrackViewHistoryLinkIntentEvent } from "../../utils/google-analytics/events/historyForm";

const SidebarContent = (props) => {

    const navItems = () => {
        return [
            { divider: true, component: <UserInfo onClickItem={()=>handleClose()} /> },
            { divider: true, component: <Divider />, closeAction: () => handleClose() },
            //{ icon: Icons.Plus, label: "Sequence Wizard", to: "/sequence/new", closeAction: () => handleClose() },
            //{ icon: Icons.Edit3, label: "Sequence Prompt", to: "/sequence/explain", closeAction: () => handleClose() },
            { divider: true, component: <Button as={NavLink} justifyContent="flex-start" w="full" to = "/sequence/new" onClick={() => TrackSequenceNewSequenceButtonIntentEvent()}><Icons.Plus/><Text ms={3}>Sequence Wizard<Text as="sup" ms={1}>beta</Text></Text></Button>, closeAction: () => handleClose()},
            { divider: true, component: <TextDivider label="History" viewAllPath= "/sequence/history" viewAllClick={() => TrackViewHistoryLinkIntentEvent()} />, closeAction: () => handleClose()},
            { divider: true, component: <History limit={10} />, closeAction: () => handleClose() },
        ];
    }

    const handleClose = () => {
        props.onClickItem();
    }

    const sidebarItemInOverMode = (item, index) => (
        <ListItem key={index}
            maxH="50vh"
            overflow="auto"
            css={{
                "&::-webkit-scrollbar": {
                    height: "var(--chakra-sizes-1)",
                    width: "var(--chakra-sizes-1)"
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "var(--chakra-colors-gray-400)"
                }
            }}
            onClick={() => { if (item.closeAction) { item.closeAction(); } }}
            >
            {item.divider ?
                <>{item.component}</>
                :
                <Link
                    display="block"
                    as={NavLink}
                    to={item.to}
                    _focus={{ bg: "primary.100" }}
                    _hover={{
                        bg: "primary.200"
                    }}
                    _activeLink={{ bg: "primary.500", color: "white" }}
                    w="full"
                    borderRadius="md"
                >
                    <Flex alignItems="center" p={2}>
                        <Icon boxSize="5" as={item.icon} />
                        <Text ml={2}>{item.label}</Text>
                    </Flex>
                </Link>}
        </ListItem>
    );
    const sidebarItemInSemiMode = (
        //{ icon: Icon, ...item },
        item,
        index
    ) => (
        <ListItem key={index}>
            {item.divider ?
                <>{item.component}</>
                :
                <Link
                    display="block"
                    as={NavLink}
                    to={item.to}
                    _focus={{ bg: "primary.100" }}
                    _hover={{
                        bg: "primary.200"
                    }}
                    _activeLink={{ bg: "primary.500", color: "white" }}
                    w="full"
                    borderRadius="md"
                >
                    <Flex alignItems="center" p={2}>
                        <Icon boxSize="5" as={item.icon} />
                        <Text ml={2}>{item.label}</Text>
                    </Flex>
                </Link>
            }
        </ListItem>
    );

    return (
        <List spacing={3}>
            {props.mode === "semi"
                ? navItems().map((item, index) => sidebarItemInSemiMode(item, index))
                : navItems().map((item, index) => sidebarItemInOverMode(item, index))}
        </List>
    );
}

export default SidebarContent;