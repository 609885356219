import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { PrivateStructure } from "../../components/layouts/PrivateStructure";
import { PublicStructure } from "../../components/layouts/PublicStructure";
import Portal from "../user/Portal";

// improvements for zones https://www.robinwieruch.de/react-router-private-routes/
export const ProtectedPath = () => {
  const { token, user } = useAuth();
  const { pathname } = useLocation();
  const loginPath = "/login";
  const userAuthPath = "/auth/user";
  const userSubscriptionsPath = "/user/subscription";
  const userCheckoutFinalizePath = "/user/checkout/finalize";

  if (!token) {
    if (loginPath === pathname) {
      return <Outlet />;
    }
    return <Navigate to={loginPath} />;
  }

  if (!user && userCheckoutFinalizePath !== pathname) {
    if (userAuthPath === pathname) {
      return <Outlet />;
    }
    return <Navigate to={userAuthPath} />;
  }

  if (userCheckoutFinalizePath !== pathname && !user.has_subscription) {
    if (userSubscriptionsPath === pathname) {
      return (<PublicStructure />);
    }
    if (user.is_expired)
    {
      return <Portal />;
    }
    else if (!user.has_subscription)
    {
      return <Navigate to={userSubscriptionsPath} />;
    }
  }

  return (<PrivateStructure />);
};