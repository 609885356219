import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const UnprotectedPath = () => {
  const { user, token, logout } = useAuth();
  const { pathname } = useLocation();
  const homePath = "/";
  const userAuthPath = "/auth/user";

  if (user) {
    if (!token)
    {
      logout();
    }
    if (homePath === pathname)
    {
      return <Outlet />;
    }
    return <Navigate to={homePath} />;
  }

  if (token)
  {
    if (userAuthPath === pathname)
    {
      return <Outlet />;
    }
    return <Navigate to="/auth/user" />;
  }
  
  return <Outlet />;
};