import {
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerBody,
    DrawerContent,
  } from '@chakra-ui/react'
import SidebarContent from './sidebarContent'
  
const Sidebar = (props) => {

    return props.variant === 'sidebar' ? (
          <SidebarContent mode = "semi" onClickItem={() => props.onClose()} />
    ) : (
      <Drawer isOpen={props.isOpen} placement="left" onClose={() => props.onClose()}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton right="0px" top="12px" />
            {/* <DrawerHeader>Chakra-UI</DrawerHeader> */}
            <DrawerBody>
              <SidebarContent mode = "over"  onClickItem={() => props.onClose()} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  }
  
  export default Sidebar;
  