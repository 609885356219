import { Box, Grid, GridItem, useBreakpointValue } from "@chakra-ui/react";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";
import { Outlet } from "react-router-dom";
import Footer from "../footer/footer";
import { useState } from "react";


export const PrivateStructure = () => {

    const smVariant = { navigation: 'drawer', navigationButton: true }
    const mdVariant = { navigation: 'sidebar', navigationButton: false }
    const [isSidebarOpen, setSidebarOpen] = useState(false)
    const variants = useBreakpointValue({ base: smVariant, md: mdVariant })
  
    const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
    }


    return (<div>
        <Grid 
          templateAreas={`'header header' 'sidebar main' 'footer footer'`} 
          templateColumns="auto 1fr"
          minH="100vh">
          <GridItem area="sidebar" as="aside" w="full" 
          p={0}
          minH="65vh"
          borderRight="1px solid"
          borderColor="gray.100">
            <Box
              pos="sticky"
              top={0}
              w={{ base: 0, md: "300px" }}
              p={{ base: 0, md: 2 }}
              paddingTop={8}
              overflow="hidden"
            >
              <Sidebar
                variant={variants?.navigation}
                isOpen={isSidebarOpen}
                onClose={toggleSidebar}
              />
            </Box>
          </GridItem>
          <GridItem area="header">
              <Header
                showSidebarButton={variants?.navigationButton}
                onShowSidebar={toggleSidebar} />
          </GridItem>
          <GridItem as="main" area="main" p={{ base: 6, md: 8 }}>
              <Outlet />
          </GridItem>
          <GridItem area="footer">
              <Footer />
          </GridItem>
        </Grid>
      </div>);
}